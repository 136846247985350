import BasiaImage from 'assets/images/About/people/basia.png';
import * as Common from 'components/_universal/Common';
import React from 'react';
import { B0, B3 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const HRBanner = () => {
    const isMd = useBreakpoint('md');
    const isSm = useBreakpoint('sm');

    return (
        <Common.Div
            flex={isSm ? 'column' : 'row'}
            gap={isMd ? '48px' : '128px'}
            mt={isMd ? 6 : 12}
        >
            <Common.Div>
                <Common.Svg src={BasiaImage} w="285px" />
                <B0 mt={2}>Barbara Warchulka</B0>
                <B3>HR Manager</B3>
            </Common.Div>
            <Common.Div>
                <B0 mb={2}>
                    It is the people who {isMd && <br />} make up the team
                </B0>
                <B3 maxW="500px">
                    We know how important the atmosphere is to the effective
                    work of the team every day. We make sure that everyone feels
                    at home at Codahead, whether you are working from the office
                    or from different corners of the world.
                </B3>
            </Common.Div>
        </Common.Div>
    );
};
