import { CommonMargin } from 'components/_layout/CommonMargin';
import { Section } from 'components/_layout/Section/Section';
import React from 'react';
import { OpenPositions } from 'sections/Career/OpenPositions/OpenPositions';
import { PerksAndBenefits } from 'sections/Career/PerksAndBenefits/PerksAndBenefits';
import { TheRecruitmentProcessSection } from 'sections/Career/TheRecruitmentProcessSection';
import { B3 } from 'styles/Typography.styled';

export default () => {
    return (
        <CommonMargin>
            <Section title="Join the team!" isFirst variant="secondary">
                <B3 mt={4.5}>
                    Be among people who want a better world and the great causes
                    making it happen. We work across multiple areas and partner
                    with a wide variety of inspirational charities and social
                    enterprises. We believe that through stronger relationships,
                    we can all make more of an impact.
                </B3>
            </Section>
            <PerksAndBenefits />
            <OpenPositions />
            <TheRecruitmentProcessSection />
        </CommonMargin>
    );
};
